<template>
    <v-select
        class="mx-1"
        outlined
        multiple
        dense
        v-model="selected_keywords"
        :items="keywords"
        label="ML Forecast Keywords"
    >
    </v-select>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({
        selected_keywords: "",
    }),
    computed: {
        keywords() {
            return [
                {
                    value: "robotic_station",
                    text: this.$store.getters.t("robotic_station"),
                },
                {
                    value: "plates",
                    text: this.$store.getters.t("plates"),
                },
                {
                    value: "framing",
                    text: this.$store.getters.t("framing"),
                },
                { value: "foil", text: this.$store.getters.t("foil") },
                {
                    value: "isolation",
                    text: this.$store.getters.t("isolation"),
                },
                {
                    value: "window",
                    text: this.$store.getters.t("window"),
                },
                {
                    value: "window_frame",
                    text: this.$store.getters.t("window_frame"),
                },
                {
                    value: "glass",
                    text: this.$store.getters.t("glass"),
                },
                {
                    value: "accesories",
                    text: this.$store.getters.t("accesories"),
                },
            ];
        },
    },
    watch: {
        value(v) {
            this.selected_keywords = v;
        },
        selected_keywords(v) {
            this.$emit("input", v);
        },
    },
};
</script>