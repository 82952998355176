<template>
    <section id="part_cats">
        <h2 class="my-4" v-if="part_cats.length != 0">
            {{ $store.getters.t("part_cats") }}
        </h2>
        <v-form ref="form">
            <div v-for="param in part_cats" :key="param.value">
                <template v-if="param.value in setup">
                    <div class="d-flex my-3">
                        <h4 class="mb-2 font-weight-light grey--text">
                            {{ param.text }}
                        </h4>
                        <v-spacer> </v-spacer>
                        <v-btn small text @click="add_item(param.value)">
                            <v-icon left> mdi-plus </v-icon>
                            ADD
                        </v-btn>
                    </div>
                    <div
                        class="text-center pa-3 grey"
                        :class="dark ? 'darken-4' : 'lighten-4'"
                        v-if="setup[param.value].length == 0"
                    >
                        <span>
                            {{ $store.getters.t("ips_no_entry") }}
                        </span>
                    </div>
                    <div
                        class="d-flex"
                        v-for="(item, i) in setup[param.value]"
                        :key="i + '_' + param.value"
                    >
                        <v-combobox
                            v-model="setup[param.value][i].ifc_prop"
                            :items="IFC_PROP_TYPES"
                            outlined
                            dense
                            hide-hints
                            label="IFC Prop"
                        ></v-combobox>
                        <v-select
                            outlined
                            dense
                            hide-hints
                            v-model="setup[param.value][i].match"
                            :items="MATCH_TYPES"
                            class="mx-2"
                            label="Type of match"
                        ></v-select>
                        <v-text-field
                            label="Value"
                            v-model="setup[param.value][i].value"
                            outlined
                            dense
                            hide-hints
                        >
                        </v-text-field>
                        <v-btn
                            small
                            class="ml-3 mt-1"
                            icon
                            @click="delete_item(param.value, i)"
                        >
                            <v-icon> mdi-minus </v-icon>
                        </v-btn>
                    </div>
                </template>
            </div>
        </v-form>
    </section>
</template>


<script>
import { backend_api } from "@/utils/api";
import { IFC_PROP_TYPES, MATCH_TYPES } from "./definitions";
export default {
    props: ["value", "section"],
    data: () => ({
        loading: false,
        setup: {},
        part_cats: [],
        IFC_PROP_TYPES,
        MATCH_TYPES,
    }),
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
    },
    watch: {
        value: {
            handler: function (setup) {
                if (setup) this.setup = setup;
                this.fill_empty_setup();
            },
            immediate: true,
        },
        setup: {
            handler: function (setup) {
                this.$emit("input", setup);
            },
            deep: true,
        },
    },
    methods: {
        add_item(param) {
            // param is the name of the param like "wood" or whatever.
            if (!this.setup[param]) this.setup[param] = [];
            this.setup[param].push({
                ifc_prop: "name",
                match: "all",
                value: "",
            });
        },
        delete_item(param, i) {
            this.setup[param].splice(i, 1);
        },
        validate() {
            // This is used also by parent component
            return this.$refs.form.validate();
        },
        save() {
            if (this.validate()) this.$emit("save");
        },
        fill_empty_setup() {
            this.part_cats.forEach((param) => {
                if (!(param.value in this.setup))
                    this.$set(this.setup, param.value, []);
            });
        },
        fetch_part_cats() {
            backend_api
                .get(`/ifc_ps_definitions/${this.section}/`)
                .then(({ data }) => {
                    this.part_cats = [...data.part_cats];
                    this.fill_empty_setup();
                });
        },
    },
    mounted() {
        this.fetch_part_cats();
    },
};
</script>