<template>
    <div class="text-center">
        <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="" v-bind="attrs" v-on="on" @click="markAllAsReaded">
                    <v-badge color="green" dot v-model="new_msgs" content="5">
                        <v-icon> mdi-bell-outline </v-icon>
                    </v-badge>
                </v-btn>
            </template>
            <v-list v-if="msgs.length != 0">
                <v-list-item :disabled="msg.readed" v-for="(msg, index) in msgs" :key="index">
                    <v-list-item-avatar>
                        <v-icon x-small color="grey" v-if="msg.readed"> mdi-circle </v-icon>
                        <v-icon x-small color="green" v-else> mdi-circle </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-subtitle> {{ msg.text }} </v-list-item-subtitle>
                </v-list-item>
            </v-list>
            <v-list v-else>
                <v-list-item>
                    <v-list-item-subtitle> No notifications </v-list-item-subtitle>
                </v-list-item>
            </v-list>

        </v-menu>
    </div>
</template>


<script>
import bus from '@/utils/event_bus'
import { backend_api } from '@/utils/api'
export default {
    data: () => ({
        msgs: [
            // { "id": 12, "contact": { "id": 4, "name": "system", "is_receiver": false }, "uuid": "e5c5f007-c78c-4afe-aab6-b64e3137d310", "text": "FOR ALL USERS, TEST 1", "metadata": {}, "created": "2022-07-15T14:11:25.072973Z", "delivered": true, "mailed": false, "readed": false, "sender": 4, "receiver": 2 },
            // { "id": 16, "contact": { "id": 4, "name": "system", "is_receiver": false }, "uuid": "5946fb94-71af-4d3e-9a1f-71e2bd631717", "text": "FOR ALL USERS, TEST 2", "metadata": {}, "created": "2022-07-15T14:11:52.929016Z", "delivered": true, "mailed": false, "readed": true, "sender": 4, "receiver": 2 }
        ],
    }),
    computed: {
        new_msgs() {
            return this.msgs.some(msg => !msg.readed)
        }
    },
    watch: {
        msgs(msgs, omsgs) {
            const ouuids = omsgs.map(omsg => omsg.uuid)
            msgs.forEach(msg => {
                if (!ouuids.includes(msg.uuid) && !msg.readed) this.notify(msg)
            })
        }
    },
    methods: {
        notify(msg) {
            bus.$emit('notification', { timeout: 5000, text: msg.text, color: "info" })
        },
        refreshMessages() {
            return backend_api.get('/messages/get_system/')
                .then(({ data }) => { this.msgs = data })
                .catch(e => console.log(`Cant load notifications: ${e}`))
        },
        markAllAsReaded() {
            this.msgs.forEach(msg => {
                if (!msg.readed)
                    return backend_api.put('/messages/' + msg.uuid + "/", {
                        ...msg, readed: true, delivered: true
                    })
            })
        }
    },
    mounted() {
        this.interval = setInterval(() => this.refreshMessages(), 5000);
    },
    beforeDestroy() {
        console.log("[i] Killing messaging interval watcher");
        clearInterval(this.interval)
    },
}
</script>