<template>
    <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
                <v-progress-linear
                    v-if="is_working"
                    color="success"
                    :value="perc"
                ></v-progress-linear>
                <v-chip v-else small :color="status_color">
                    {{ item.status }}
                </v-chip>
            </div>
        </template>
        <span>
            {{ status_toop_tip }}
        </span>
    </v-tooltip>
</template>

<script>
export default {
    props: ["item"],
    computed: {
        status_toop_tip() {
            if (this.item.status == "ready") return "Ready";
            else if (this.item.status == "error")
                return "The server can't complete the work.";
            else if (this.item.status == "waiting")
                return "The server is working in other jobs. Wait.";
            else if (this.item.status == "deleting") return "Deleting...";
            else if (this.item.status == "working")
                return "The job is being processed.";
            else if (this.item.status.includes("working_"))
                return `${this.item.status.split("_")[1]}%`;
            else return this.item.status;
        },
        status_color() {
            if (this.item.status == "ready") return "success";
            else if (this.item.status == "error") return "error";
            else if (this.item.status == "working") return "warning";
            else if (this.item.status == "deleting") return "grey";
            else return "info";
        },
        is_working() {
            return this.item.status.includes("working_");
        },
        perc() {
            return this.item.status.split("_")[1];
        },
    },
};
</script>