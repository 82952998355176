<template>
    <v-select
        outlined
        dense
        hide-hints
        v-model="selected_line"
        :items="production_sites"
        :disabled="disabled"
        :label="$store.getters.t('production_site')"
        @change="$emit('change')"
    ></v-select>
</template>

<script>
export default {
    props: ["value", "disabled"],
    data: () => ({
        selected_line: "",
    }),
    computed: {
        production_sites() {
            return this.$store.state.company.production_sites.map((ps) => ({
                text: ps.name,
                value: ps.uuid,
            }));
        },
    },
    watch: {
        selected_line(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_line = v;
            },
            immediate: true,
        },
    },
    mounted() {
        this.$store.dispatch("company/loadProductionSites");
    },
};
</script>