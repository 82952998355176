<template>
    <v-select outlined dense class="mx-1" v-model="selected_btlps" :items="btl_parsing_setups" :rules="rules"
        :clearable="!required" :label="$store.getters.t('btl_parsing_setup')">
    </v-select>
</template>

<script>
export default {
    props: {
        value: { default: null },
        required: { type: Boolean, default: false },
    },
    data: () => ({
        selected_btlps: "",
    }),
    computed: {
        btl_parsing_setups() {
            return this.$store.state.company.btl_parsing_setups.map((ips) => ({
                text: ips.name,
                value: ips.uuid,
            }));
        },
        rules() {
            if (!this.required) return []
            return [(v) => !!v || 'An BTL Parsing Setup is required']
        },
    },
    watch: {
        selected_btlps(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_btlps = v;
            },
            immediate: true,
        },
    },
    mounted() {
        this.$store.dispatch("company/loadBtlParsingSetups");
    },
};
</script>