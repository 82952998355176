<template>
    <v-btn :disabled="loading" :loading="loading" icon @click="deleteItem()">
        <v-icon>mdi-delete</v-icon>
    </v-btn>
</template>

<script>
export default {
    props: ["item"],
    data: () => ({ loading: false }),
    methods: {
        deleteItem() {
            this.loading = true;
            this.$store
                .dispatch("litespec/deleteLiteJob", this.item.uuid)
                .finally(() => (this.loading = false));
        },
    },
};
</script>