<template>
    <section id="branches">
        <div class="d-flex">
            <h2 class="my-2">
                {{ $store.getters.t("branches") }}
            </h2>
            <v-spacer></v-spacer>
            <v-btn color="accent" text @click="new_branch">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("new") }}
            </v-btn>
        </div>
        <v-form ref="form">
            <template v-for="(branch, i) in branches">
                <v-divider class="mt-2" inset :key="i + '_divider'">
                </v-divider>

                <div class="d-flex mb-2" :key="i + '_d'">
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="delete_branch(i)">
                        <v-icon left> mdi-minus </v-icon>
                        {{ $store.getters.t("delete_branch") }}
                    </v-btn>
                </div>
                <div class="d-flex" :key="i">
                    <div style="width: 250px">
                        <h4 class="mb-2 font-weight-light grey--text">
                            {{ $store.getters.t("name") }}
                        </h4>
                        <v-combobox
                            label="Branch Name"
                            v-model="branch.name"
                            outlined
                            dense
                            hide-hints
                            :items="TYPICAL_BRANCHES"
                        >
                        </v-combobox>
                    </div>
                    <div class="ml-5" style="width: 700px">
                        <div class="d-flex">
                            <h4 class="mb-2 font-weight-light grey--text">
                                {{ $store.getters.t("entries") }}
                            </h4>
                            <v-spacer> </v-spacer>
                            <v-btn small text @click="add_item(i)">
                                <v-icon left> mdi-plus </v-icon>
                                {{ $store.getters.t("add_item") }}
                            </v-btn>
                        </div>
                        <div
                            class="text-center pa-3 grey"
                            :class="dark ? 'darken-4' : 'lighten-4'"
                            v-if="branch.entries == 0"
                        >
                            <span>
                                {{ $store.getters.t("ips_no_entry") }}
                            </span>
                        </div>
                        <div
                            class="d-flex"
                            v-for="(item, j) in branch.entries"
                            :key="i + '_' + j"
                        >
                            <v-combobox
                                v-model="branches[i].entries[j].ifc_prop"
                                :items="IFC_PROP_TYPES"
                                outlined
                                dense
                                hide-hints
                                label="IFC Prop"
                            ></v-combobox>
                            <v-select
                                outlined
                                dense
                                hide-hints
                                v-model="branches[i].entries[j].match"
                                :items="MATCH_TYPES"
                                class="mx-2"
                                label="Type of match"
                            ></v-select>
                            <v-text-field
                                label="Value"
                                v-model="branches[i].entries[j].value"
                                outlined
                                dense
                                hide-hints
                            >
                            </v-text-field>
                            <v-btn
                                small
                                class="ml-3 mt-1"
                                icon
                                @click="delete_item(i, j)"
                            >
                                <v-icon> mdi-minus </v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </template>
        </v-form>
    </section>
</template>

<script>
import { TYPICAL_BRANCHES, IFC_PROP_TYPES, MATCH_TYPES } from "./definitions";
export default {
    props: ["value", "section"],
    data: () => ({
        loading: false,
        branches: [],
        TYPICAL_BRANCHES,
        IFC_PROP_TYPES,
        MATCH_TYPES,
    }),
    computed: {
        dark() {
            return this.$vuetify.theme.dark;
        },
    },
    watch: {
        value: {
            handler: function (branches) {
                if (branches) this.branches = branches;
            },
            immediate: true,
        },
        branches: {
            handler: function (branches) {
                this.$emit("input", branches);
            },
            deep: true,
        },
    },
    methods: {
        new_branch() {
            console.log("ADDNG");
            this.branches.push({
                name: "",
                entries: [],
            });
        },
        add_item(i) {
            this.branches[i].entries.push({
                ifc_prop: "name",
                match: "all",
                value: "",
            });
        },
        delete_branch(i) {
            this.branches.splice(i, 1);
        },
        delete_item(i, j) {
            this.branches[i].splice(j, 1);
        },
        validate() {
            // This is used also by parent component
            return this.$refs.form.validate();
        },
        save() {
            if (this.validate()) this.$emit("save");
        },
    },
};
</script>