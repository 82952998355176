<template>
    <v-select
        outlined
        dense
        class="mx-1"
        multiple
        v-model="selected_branches"
        :items="branches"
        :label="$store.getters.t('branches')"
    >
    </v-select>
</template>

<script>
export default {
    props: ["value"],
    data: () => ({
        selected_branches: "",
    }),
    computed: {
        ifc_parsing_setups() {
            return this.$store.state.company.ifc_parsing_setups;
        },
        selected_project() {
            return this.$store.state.projects.selected_project;
        },
        selected_ifcps() {
            return this.ifc_parsing_setups.find(
                (ifcps) => ifcps.uuid == this.selected_project.ifc_parsing_setup
            );
        },
        branches() {
            let branches = ["unknown"];
            this.selected_ifcps?.custom_branches?.forEach((br) =>
                branches.push(br.name.toLowerCase())
            );
            return branches.filter((br) => br); // clean empties
        },
    },
    watch: {
        selected_branches(v) {
            this.$emit("input", v);
        },
        value: {
            handler: function (v) {
                this.selected_branches = v;
            },
            immediate: true,
        },
    },
    mounted() {
        this.$store.dispatch("company/loadIfcParsingSetups");
    },
};
</script>