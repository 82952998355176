<template>
    <v-container>
        <v-row>
            <v-spacer></v-spacer>
            <v-checkbox
                class="mx-3"
                v-model="is_machine"
                :label="$store.getters.t('machine_station')"
            ></v-checkbox>
        </v-row>
        <v-row v-if="is_machine">
            <v-select
                full-width
                class="mx-1"
                outlined
                dense
                v-model="selected_setup"
                :items="machine_setups"
                label="Machine Setup"
            >
            </v-select>
        </v-row>
        <v-row v-else>
            <v-spacer></v-spacer>
            <span class="caption mx-3">
                {{ $store.getters.t("manual_station") }}
            </span>
        </v-row>
    </v-container>
</template>
<style lang="scss" scoped>
v-select {
    width: 30px;
}
</style>

<script>
export default {
    props: ["value"],
    data: () => ({ selected_setup: "", is_machine: false }),
    watch: {
        value: {
            handler: function (v) {
                this.selected_setup = v;
                this.is_machine = !!v;
            },
            immediate: true,
        },
        selected_setup(setup) {
            this.$emit("input", setup);
        },
        is_machine(is) {
            if (!is) this.selected_setup = null;
        },
    },
    computed: {
        machines() {
            return this.$store.state.company.machines;
        },
        machine_setups() {
            let setups = [{ text: "No setup", value: null }];
            this.machines.forEach((ms) => {
                setups.push({
                    value: ms.uuid,
                    text: ms.name,
                });
            });
            return setups;
        },
    },
};
</script>