function by_name(a, b) {
    if (a.name > b.name) {
        return 1;
    }
    if (a.name < b.name) {
        return -1;
    }
    // a must be equal to b
    return 0;
}

function by_username(a, b) {
    if (a.username > b.username) {
        return 1;
    }
    if (a.username < b.username) {
        return -1;
    }
    // a must be equal to b
    return 0;
}

function by_short_name_number(a, b) {
    const an = a.short_name.split(" - ")[1]
    const bn = b.short_name.split(" - ")[1]
    if (an > bn) {
        return 1;
    }
    if (an < bn) {
        return -1;
    }
    // a must be equal to b
    return -1;
}


export { by_name, by_username, by_short_name_number }