import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";

Vue.config.productionTip = false


if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    Vue,
    dsn: "https://e919ecbf6c5f453b9c824b4bc07586dd@o357522.ingest.sentry.io/6168915",
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "mobgenerator.com", /^\//],
      }),
    ],
    release: "mob-lite@" + process.env.npm_package_version,
    tracesSampleRate: 0.1,
    environment: process.env.NODE_ENV,
  });
}


export default new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
