<template>
    <div class="text-center">
        <v-dialog v-model="dialog" width="800">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon> mdi-code-json </v-icon>
                </v-btn>
            </template>

            <v-card>
                <v-card-title>
                    {{ title || "JSON Metadata" }}
                </v-card-title>

                <v-card-text ref="renderzone"> </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="dialog = false">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import JSONFormatter from "json-formatter-js";

export default {
    props: ["title", "json"],
    data() {
        return {
            dialog: false,
        };
    },
    watch: {
        json: {
            deep: true,
            handler: function () {
                this.$nextTick(this.format);
            },
        },
        dialog: {
            immediate: true,
            handler: function () {
                this.$nextTick(this.format);
            },
        },
    },
    methods: {
        format() {
            let deep = 2;
            const options = {
                theme: this.$vuetify.theme.dark ? "dark" : "",
            };
            const formatter = new JSONFormatter(this.json, deep, options);
            const rendered = formatter.render();
            let node = this.$refs["renderzone"];
            if (node) {
                node.innerHTML = "";
                node.appendChild(rendered);
            }
        },
    },
    mounted() {
        this.format();
    },
};
</script>