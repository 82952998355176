export const MATCH_TYPES = [
    { text: "Match full word", value: "all" },
    { text: "Match prefix", value: "prefix" },
]

export const IFC_PROP_TYPES = [
    "name",
    "ifc_type",
    "ifc_material",
    "description",
    "object_type",
    "reference",
    "zone",
    "profile",
    "material_type",
    "material",
    "finish",
    "class",
]

export const TYPICAL_BRANCHES = [
    "windows",
    "connectors",
    "aluminium",
    "airtightness",
    "electrical",
    "air_ducts",
    "plumbing",
]