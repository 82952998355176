<template>
    <v-container>
        <v-row class="mt-5"> </v-row>
        <v-row>
            <v-col>
                <TableD2FLicenses />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import TableD2FLicenses from "@/components/litespec/d2f/TableD2FLicenses";
export default {
    components: {
        TableD2FLicenses,
    },
    data: () => ({}),
    computed: {},
    mounted() {},
};
</script>