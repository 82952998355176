<template>
    <div class="my-2">
        <SelectProductionLine v-model="production_line" />
        <SelectIFCPS v-model="ifcps" />
        <SelectBTLPS v-model="btlps" />
        <v-row v-for="df in pl_dynamic_forms" :key="df.key">
            <DynamicForm
                :title="df.title"
                :form_name="df.form_name"
                :options_resource="df.options_resource"
                v-model="dynamic_forms[df.key]"
            />
        </v-row>
    </div>
</template>

<script>
import SelectProductionLine from "@/components/reports/SelectProductionLine";
import SelectIFCPS from "@/components/ifcps/SelectIFCPS";
import SelectBTLPS from "@/components/ifcps/SelectBTLPS";
import DynamicForm from "@/components/DynamicForm";
import bus from "@/utils/event_bus";
import { backend_api } from "@/utils/api";
export default {
    components: { SelectProductionLine, SelectIFCPS, SelectBTLPS, DynamicForm },
    data: () => ({
        ifcps: "",
        btlps: "",
        production_line: "",
        dynamic_forms: {},
        machine_models: [], // Async loaded
    }),
    computed: {
        pl_object() {
            return this.$store.state.company.production_sites.find(
                (pl) => pl.uuid == this.production_line
            );
        },
        pl_dynamic_forms() {
            if (this.production_line == "") return [];
            let dfs = [];
            this.pl_object?.stations?.forEach((sta) => {
                // if (sta.machine_setup) {
                //     let ms = this.$store.state.company.machines.find(
                //         (m) => m.uuid == sta.machine_setup
                //     );
                //     let ms_model;
                //     if (ms.model == "mobi_one_v2") ms_model = "mobi_one_v2_job";
                //     else if (ms.model == "mobi_one_v1")
                //         ms_model = "mobi_one_v1_job";
                //     else if (ms.model == "robobrick")
                //         ms_model = "robobrick_job";
                //     else ms_model = "null";
                //     dfs.push({
                //         form_name: ms_model,
                //         options_resource: ms.parsed_setup,
                //         station: ms.uuid,
                //         station_name: ms.name,
                //     });
                // }

                const machine_uuid = sta.machine_setup;
                if (machine_uuid) {
                    let machine = this.$store.state.company.machines.find(
                        (m) => m.uuid == machine_uuid
                    );
                    let model = machine?.model;
                    const mm = this.machine_models.find(
                        (mm) => mm.name == model
                    );
                    mm?.formats.forEach((f) =>
                        dfs.push({
                            form_name: f.settings,
                            key: machine_uuid,
                            title: sta.name + ": Station Job Options",
                            options_resource: machine.parsed_setup,
                        })
                    );
                }
            });
            return dfs;
        },
        lock_generate() {
            return (
                (this.btlps == "" || this.ifcps == "") &&
                this.production_line == ""
            );
        },
    },
    methods: {
        generate(files) {
            const payload = {
                input_files: files,
                ifcps: this.ifcps,
                btlps: this.btlps,
                production_line: this.production_line,
                dynamic_forms: this.dynamic_forms,
            };
            if (this.lock_generate) {
                bus.$emit("notification", {
                    timeout: 3000,
                    text: "Please select Production Line and IFC/BTL Parsing Setup.",
                    color: "warning",
                });
            } else {
                this.$store.dispatch("generateFiles", payload);
            }
        },
        load_machine_models() {
            backend_api
                .get("/machine_models/")
                .then((r) => (this.machine_models = r.data));
        },
    },
    mounted() {
        this.load_machine_models();
    },
};
</script>