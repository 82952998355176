import { en } from 'vuetify/es5/locale'

export default {
    ...en,

    // Main
    account: "Account",
    company: "Company",
    project: "Project",
    full_project: "General Arrangement",
    projects: "Projects",
    phase: "Phase",
    phases: "Phases",
    batch: "Batch",
    batches: "Batches",
    phases_and_batches: "Phases and batches",
    wall_unit: "Wall unit",
    wall_units: "Wall units",
    panels: "Panels",
    panel: "Panel",
    title: "Title",
    text: "Text",
    status: "Status",
    priority: "Priority",
    pictures: "Pictures",
    wall: "Wall design",
    walls: "Wall designs",
    production_site: "Production line",
    production_line: "Production line",
    site_parameters: "Line parameters",
    production_station: "Production station",
    managed_users: "Managed users",
    panel_viewer: "Panel Viewer",
    time_entries: "Time entries",
    read_qr: "Read QR",
    resync: "Resync data from server",
    submit: "Submit",
    new: "New",
    hide: "Hide",
    show: "Show",
    archive: "Archive",
    archived_projects: "Archived projects",
    close: "Close",
    delete: "Delete",
    add: "Add",
    solve: "Solve",
    select: "Select",
    save: "Save",
    open: "Open",
    edit: "Edit",
    see_more: "See more",
    view_meaning_action_of_view: "View",
    view_meaning_presentation: "View",
    quota_exceeded: "Your company exceeded their quota.",
    quota_almost_exceeded: "COMPANY PANELS QUOTA ALMOST EXCEEDED",
    upload: "Upload",
    uploads: "Uploads",
    return: "Return",
    ready: "Ready",
    name: "Name",
    version: "Version",
    uuid: "UUID",
    user: "User",
    users: "Users",
    username: "Username",
    email: "email",
    role: "Role",
    login: "Login",
    recovery: "Recovery",
    logout: "Logout",
    confirm: "Are you sure?",
    print: "Print",
    _and: "and",
    _more: "more",
    zone: "Zone",
    zones: "Zones",
    next: "Next",
    selection: "Selection",
    no_selection: "No selection",
    drawing: "Drawing",
    category: "Category",
    content: "Content",
    welcome_to: "Welcome to",
    ordering: "Ordering",
    launch: "Launch",
    launch_all: "Launch all",
    panel_viewer_mode: "Panel Viewer",
    management_mode: "Management",
    production_mode: "Production",
    logistics_mode: "Logistics",
    management_mode_short: "Manage",
    production_mode_short: "Prod",
    logistics_mode_short: "Logistics",
    change_station: "Change Station",
    change_station_help_text: "Work on another station of the production line",
    can_select_station: "🔃 Can change station",
    has_own_worktime: "🔨 Has own worktime",
    has_own_holidays: "🌴 Has own holidays",
    android_licensed: "🤖 Android licensed",
    reached_limit: "Limit reached",
    reached_limit_explain: "Please remove license from other user or contact support to get more Android licenses.",
    no_station: "NO STATION",
    no_production_line: "NO PRODUCTION LINE",
    add_item: "Add item",
    timestamp: "Timestamp",


    // Login
    check_email_token_warning: "Check your email and enter the token",
    password_recovery: "Password recovery",
    token: "Token",
    password: "Password",
    password_again: "Password again",
    back: "Back",
    wrong_credentials: "Wrong credentials",

    // Company view
    company_settings: "Company settings",
    company_wide: "Company Wide",
    address: "Address",
    admin_email: "Admin email",
    contact_email: "Contact email",
    licenses: "Licenses",
    max_ready_panels: "Max ready panels",
    remaining_panels_quota: "Remaining panels quota",
    max_woodwall_tekla_licenses: "Max WoodWall Tekla Licenses",
    max_android_licenses: "Max Android Licenses",
    m_core: "Module production",
    m_bom: "Module BoM",
    module_delivery: "Module Delivery",
    styles: "Styles",
    company_files_for_ui: "Company files for reports and UI customization",
    logo: "Logo",
    sticker_banner: "Sticker Banner",
    client: "Client",
    pr_number: "Pr Number",
    delete_user: "Delete user",
    delete_user_confirm: "Do you want to delete this user?",
    edit_user: "Edit user",
    new_user: "New user",
    user_parameters: "User parameters",
    android_license: "Android license",
    android_licenses: "Android licenses",
    password_reset: "Password reset",
    change_password: "Change password",
    production_lines: "Production lines",
    default_zone: "Default zone",
    stations: "Stations",
    delete_production_site: "Delete production line",
    delete_production_site_confirm: "Do you want to delete the production line?",
    add_station: "Add station",
    last: "Last",
    station_parameters: "Station parameters",
    station_options: "Station options",
    delete_station: "Delete station",
    machine_station: "Machine station",
    manual_station: "Manual station",
    tekla_licenses: "Tekla Licenses",
    active_licenses: "Active Licenses",
    comment: "Comment",
    active: "Active",
    machine_hash: "Machine Hash",
    delete_license: "Delete license",
    delete_license_confirm: "Do you want to delete the license?",
    unlock: "Unlock",
    unlock_license: "unlock license",
    unlock_license_confirm: "Do you want to unlock the license?",
    license: "License",
    work_calendar: "Work calendar",
    work_calendar_subtitle: "Weekly working hours and breaks",
    add_entry: "Add entry",
    holyday_calendar: "🌴 Holyday calendar",
    non_working_days: "Non working days",
    its_a_break: "It's a break",
    break_text: "☕ Break entry",
    worktime_text: "🔨 Worktime entry",
    calendar_range: "📅 Calendar Range",
    ifc_parsing_setups: "IFC Parsing Setups",
    ifc_parsing_setup: "IFC Parsing Setup",
    delete_ifc_parsing_setup: "Delete IFC Parsing Setup",
    set_at_projects: "Set at projects",
    this_ips_will_be_deleted: "The IFC Parsing Setup will be deleted. Please review your active projects settings.",
    ips_no_entry: "Please add at least one identifier for this category",
    part_cats: "Part categories",
    part_props: "Part properties",
    show_qr_button: "Show QR button",
    show_rfid_button: "Show RFID button",
    hide_hidden_mats: "Hide 'Hidden Materials' in 3D",


    // Machine
    machine: "Machine",
    machines: "Machines",
    delete_machine: "Delete machine",
    this_machine_will_be_deleted: "This machine will be deleted.",
    upload_xml: "Upload Machine File (XML, INI)",
    machine_setup: "Machine setup",
    mob2_generator_defaults: "MOB2 Generator Default Values",
    parsed_params: "Parsed params",


    // Project Status
    project_status: "Project Status",

    // Designs
    designs: "Designs",
    design: "Design",
    delete_design: "Delete design",
    delete_design_warning: "All the data linked to this design will be deleted (panels, worker time entries, issues).",
    delete_design_warning_multiple: "All the data linked to following designs will be deleted (panels, worker time entries, issues).",
    delete_also_files: "Delete also related files",
    missing_wall_metadata: "Missing Wall Metadata",
    missing_wall_parts: "Missing Wall Parts",
    wall_metadata: "Wall metadata",
    wall_metadata_subtitle: "This information is automatically generated on file analysis. It can be also overriden by the user.",
    wall_parts_and_metadata: "Wall parts and metadata",
    revision: "Revision",
    keep_current_revisions: "Keep current revision numbers",
    analysis_dialog_help_text: "Launch analysis task for project files. You can also analyze again all files as a new project.",
    analysis_task_launcher: "Analysis task launcher",
    analysis_option_fast: "Analyze new files [Fast, standard]",
    analysis_option_slow: "Analyze all files in the project [Slow, fix problems]",
    analysis_from_ifc: "Make one panel design for each single IFC",
    analysis_from_arrangement_ifc: "Make panel designs from the full project IFC",
    analysis_from_jjs: "Make one panel design for each single JJS",
    analysis_status_queued: "Analysis queued",
    analysis_status_launch: "Analysis launched",
    analysis_status_failed: "Analysis failed",
    analysis_status_completed: "Completed",
    analysis_status_split: "Splitting IFCs",
    analysis_status_names: "Building designs structure",
    analysis_status_dwg: "Drawing analysis",
    analysis_status_ifc: "IFC analysis",
    analysis_status_undefined: "Unknown status",
    width: "Width",
    length: "Length",
    thickness: "Thickness",
    bruto_area: "Bruto Area",
    neto_area: "Neto Area",
    dwgs: "DWGs",
    ifcs: "IFCs",
    arrangement_ifcs: "Project IFCs",
    show_files: "Show files",
    linked_details: "Linked details and docs",
    linked_details_button_text: "Dets & docs",
    linked_details_help: "Select linked details and docs to be shown in the right side of the viewer when this design is selected.",


    // Panels
    panel_ordering: "Panel ordering",
    panel_ordering_subtitle: "Drag and drop panels for production ordering",
    panel_type: "Panel Type",
    panels_management: "Panels management",
    panels_list: "Panels list",
    ready_panel_proof: "Ready panel proof",


    // Materials orders and logistics
    material_orders: "Material orders",
    total_count: "Total count",
    orders: "Orders",
    new_order: "New order",

    // QR - RFID
    qr_code: "QR code",
    scan_qr: "Scan QR",
    rfid: "RFID",
    register_panel_rfid_tag: "Register panel RFID tag.",
    rfid_info_text: "RFID codes can be registered here for future scan of the QR code shown in the RFID tag emulating an actual scan of the RFID tag.",


    // BOM
    ifc_id: "IFC ID",
    ifc_tag: "IFC Tag",
    ifc_reference: "IFC Reference",
    ifc_props: "Ifc Props",
    _3d_view: "3D View",
    parts: "Parts",


    // Uploads
    upload_project_files: "Upload project files",
    upload_files_for_project: "Upload files for project",
    upload_files: "Upload Files",
    upload_info_text: "Once you've uploaded the project files, you need to launch an analysis task for the system to detect details, convert IFC files to 3D models, and prepare the whole system for the viewer to work properly. Remember that a new revision will be created in each panel design.",
    launch_analysis: "Analyze Files",
    analyzed: "Analyzed",
    not_analyzed: "Not analyzed",
    backend_analysis_running: "Analysis running in the server",
    select_file_cat: "Select file group",
    drawings: "Drawings (.pdf)",
    details: "Details (.pdf)",
    docs: "Documentation (.pdf, .jpeg, .png)",
    ifc: "IFCs (.ifc)",
    arrangement_ifc: "Full project IFC (.ifc)",
    machine_files: "Machine Files (.jjs, .wup)",
    files: "Files",
    type: "Type",


    // New analysis button
    operations: "Operations",
    project_operations: "Project Operations",
    operations_dialog_help_text: "Launch operations over previously uploaded files. Available operations:",
    split_ifc: "Split full IFC",
    generate_designs: "Generate designs",
    detect_details: "Detect details",
    prepare_viewer: "Prepare viewer for production (deep IFC analysis)",
    clean_stucked_jobs: "Clean stucked jobs",
    help: "Help",
    last_run: "Last run",
    also_already_scanned_files: "Also already scaned files",
    scan_ifc: "Scan IFC files",
    scan_dwg: "Scan drawings",



    // Annotations
    new_annotation_for_wu: "New annotation for wall unit",
    attach_piece_info: "Attach part info",
    annotations: "Annotations",
    notify_others: "Notify others in production",
    issue_attached_to_panel: "This issue will be attached to panel",
    uploader_help_text: "Attach picture. Drop, paste, browse or get from",
    list: "List",
    issue: "Issue",
    issues: "Issues",
    list_by_batch: "List by batch",
    note: "Note",
    delete_annotation_question: "Delete issue?",
    solve_annotation: "Solve issue",
    select_revision_annotation: "Please select in what revision was the annotation solved.",
    anno_cat_prod_issue: "Production Issue",
    anno_cat_quality_control: "Quality Control Issue",
    created: "Created",
    solved: "Solved",
    solved_in_revision: "Solved in revision",
    solved_coment: "Solving comment",


    // Calendar
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",


    // Reports
    reports: "Reports",
    create_first_report: "Create the first report for this project.",
    not_report_module: "Your Company doesn't have the REPORT MODULE activated.",
    new_report: "New report",
    generate_report_for_pr: "Generate new report for project",
    wu_to_report: "Wall units to be reported",
    w_to_report: "Wall designs to be reported",
    document_type: "Document type",
    last_report_status: "Last report status",
    document_status: "Document status",
    built: "Built",
    report_type: "Report Type",
    paper_size: "Paper size",
    document_comment: "Document comment",
    materials_totals_page: "Materials totals page",
    sort_by_priority: "Sort by priority",
    specific_fields: "Specific fields",
    rep_status_for_information: "For information",
    rep_status_for_ordering: "For ordering",
    rep_status_temporary: "Temporary",
    rep_status_final: "Final",
    report_search: "Report search",
    full_sizes: "Full sizes (ommited for cutting)",

    // Drawings
    select_drawing: "Select a drawing",
    wall_design_drawings: "Wall design drawings",
    wall_design_documents: "Wall design documents",
    loading_pdf: "Loading PDF",
    designs_and_uploads: "Designs & Uploads",

    // Projects
    select_project: "Select a project",
    manage_docs: "Manage Docs",
    manage_units: "Manage Units",
    project_settings: "Project Settings",
    delete_project: "Delete project",
    delete_project_confirm: "Do you want to delete the project?",
    project_params: "Project parameters",
    superproject: "Superproject",
    grouped_projects: "Grouped projects",
    project_type: "Project type",
    detail_scanning: "Detail scanning",
    start_date: "Start date",
    end_date: "End date",
    delivery_date: "Delivery date",

    // Manager / Schedule / Batches / Phases
    exit_schedule: "Exit Schedule",
    schedule: "Schedule",
    generate_panel_units: "GENERATE PANELS",
    delete_batch: "Delete batch", // delete batch XXXX for project: xxxxxx
    hide_batch: "Hide batch", // delete batch XXXX for project: xxxxxx
    hiding_batch_help_text: "The batch will be hidden/shown for production user with production line.",
    for_project: "for project", // delete batch XXXX for project: xxxxxx
    add_batch: "Add batch",
    batch_name: "Batch name",
    batch_uuid: "Batch uuid",
    batch_report: "Batch report",
    batch_ordering: "Batch ordering",
    batch_ordering_subtitle: "Drag and drop batches for production ordering",
    existing_batch: "There is another batch with the same name.",
    new_batch_for_project: "New batch for project", // New batch for project XXX in phase XXX
    in_phase: "in phase", // New batch for project XXX in phase XXX
    change_batch_name: "Change batch name",
    clone_batch: "Clone batch",
    clone_batch_warning: "Batch will be replicated. Panels will not be created until you run the generator.",
    add_phase: "Add phase",
    select_a_phase: "Select a phase",
    new_phase_for_project: "New phase for project",
    existing_phase: "There is a phase with the same name.",
    delete_phase: "Delete phase",
    delete_phase_warning: "Following batches will be deleted too. Also wall units, time entries and other asociated data to this batches will be deleted!",
    change_phase_name: "Change phase name",
    set_multiple_numbers: "Set multiple numbers",
    set_numbers: "Set numbers",
    multiple_numbers_warning: "Write a count number and select the panels you want to update.",
    clone_phase: "Clone phase",
    clone_phase_warning: "Phase will be replicated using same batch naming and counts. Phase and batches will be created now, panels will not be created until you run the generator.",
    phase_name: "Phase name",
    count: "Count",
    all: "All",
    clone: "Clone",
    actions: "Actions",
    wall_units_ordered: "Ordered panels",
    batch_priority: "Batch priority",
    totals: "Totals",
    total_panels: "Total panels",
    phase_total: "Phase total",
    project_total: "Project total",
    panel_matrix: "Panel matrix",
    priority_in_batch: "Priority in batch",
    order_by_design: "Order by design",
    is_last: "Is last",
    order_by_name: "Order by name",
    save_counts: "Save counts",
    reset_counts: "Reset counts",
    hidden_batches: "Hidden batches found!",
    hidden_batches_explanation: "There are hidden batches for his project. They are batches assigned to non existent phases and they break the counts. Please create that phases and then delete if you want:",



    // Viewer
    select_panel: "Select a panel",
    wood: "Wood",
    plates: "Plates",
    accesories: "Accesories",
    materials: "Materials",
    select_part_to_hightlight: "Select an element to highlight in the 3D view",
    first_scheduled_panel: "First scheduled panel",
    next_scheduled_panel: "Next scheduled panels",
    ready_panels: "Ready panels",
    working_panels: "Working panels",
    waiting_panels: "Waiting panels",
    wall_design_browser: "Wall design browser",
    loading_ifc: "Loading IFC",


    // Checklist + timer
    checklist_short_name: "Tasks",
    _checklist: "checklist",
    checklist: "Checklist",
    checklist_items: "Checklist items",
    checklist_station_complete: "JOB FOR YOUR STATION IS COMPLETED",
    clean: "Clean",
    upload_picture: "Upload pictures",
    _picture_saved: "photo(s) saved",
    timer_start: "Start",
    timer_stop: "Stop",
    timer_runing_advice: "A timer is running. Please stop the timer before setting the panel as ready.",
    timer_hidden: "Timer hidden for wall designs.",
    stop_timer_title: "Stop production",
    start_timer_title: "Start production",
    start_production: "Start production",
    just_looking: "Just looking",
    stop_reason_eod: "End of day",
    just_looking_dialog: "This is not the scheduled panel. What to do?",
    stop_reason_break: "Break",
    stop_reason_issues: "Production Issues",
    stop_reason_eow: "Finished my work for this panel",
    stop_reason_unknown: "Other reasons",
    panel_ready: "Panel ready",
    station_job_completed: "Station job completed",


    // TASKS

    robotic_station: "Robotic/Machine Station",
    // plates: "Plates",
    framing: "Framing",
    foil: "Foil",
    isolation: "Isolation",
    window: "Window",
    window_frame: "Window Frame",
    glass: "Glass",
    // accesories: "Accesories",  <- already exists


    // checklist confirm sentence be like: "Checklist for unit XXXX on
    // station XXXX of production line XXXX. Please confirm completed tasks and
    // upload required pictures"
    checklist_confirm_1: "Checklist for unit",
    checklist_confirm_2: "on station",
    checklist_confirm_3: "of production line",
    checklist_confirm_4: "Please confirm completed tasks and upload required pictures",


    // Forecast
    forecast: "Forecast",
    production_forecast: "Production Forecast",
    production_overview: "Production Overview",
    project_overview: "Project Overview",
    no_fc_warning: "This panel had no production line when the forecast ran.",
    minutes_lowercase: "minutes",
    loading_production: "Loading production...",
    working_on_schedule: "Working on new schedule",
    updated: "Updated",
    reloading: "Reloading",
    run_scheduler: "Run Scheduler",
    last_scheduler_errors: "Last scheduler ran with errors",
    overview_touched: "There are changes that were not applied. Run scheduler again",
    times: "Times",
    timestamps: "Timestamps",
    real_hours: "Real hours",
    fc_hours: "Forecast hours",
    company_calculated_hours: "Company expected hours",
    use_for_schedule: "Use for schedule",
    loose_batches: "No Prod Line Batches",
    warning_date_alert: "This batch can't be delivered on time",

    // Chat
    send_message_to: "Send message to",
    message_people_title: "Select contacts",
    message_people_subtitle: "Selected users will receive a message directly into the chat.",


    // Lang
    language: "Language",
    multilang: "🌐 Multilanguage",
    select_lang: "Select language for your interface",

    hide_panel_queue: "👁 Hide panel queue",


    // 2FA
    clear: "Clear",
    disable: "Disable",
    scanned: "Scanned",
    otp_help_text: "Two Factor Auth increses the security of your account. You can setup the OTP (One Time Passoword) in your smartphone with any OPT enabled app, just like Google Authenticator or Authy.",

    // MOBGENERATOR
    no_files: "No files",

    // SPECS
    define_specs: "Define specs",
    specs: "Specs",
    specifications: "Specifications",
    add_as_text: "Add as text",
    docs_as_text: "Docs as TXT",

}

