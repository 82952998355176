<template>
    <v-form @keyup.enter.native="save" ref="form">
        <v-row>
            <v-col>
                <v-text-field
                    label="Machine Setup Name"
                    v-model="form.name"
                    outlined
                    hide-hints
                    dense
                    :rules="[(v) => !!v || 'Name required']"
                >
                </v-text-field>
            </v-col>
            <v-col>
                <v-select
                    outlined
                    dense
                    hide-hints
                    v-model="form.model"
                    :items="model_types"
                    label="Model"
                ></v-select>
            </v-col>
        </v-row>
        <v-row v-if="machine_description_long" class="my-0">
            <v-col>
                <v-card-subtitle>
                    {{ machine_description_long }}
                </v-card-subtitle>
            </v-col>
        </v-row>
        <template v-if="can_upload_config">
            <v-row>
                <v-col>
                    <v-card-subtitle>
                        {{ $store.getters.t("upload_xml") }}
                    </v-card-subtitle>
                </v-col>
                <v-col>
                    <v-card-subtitle>
                        {{ $store.getters.t("parsed_params") }}
                    </v-card-subtitle>
                </v-col>
            </v-row>
            <MachineFileUploader
                :machine_model="form.model"
                v-model="form.parsed_setup"
                @config_file_update="config_file_update"
            />
        </template>
        <v-row v-if="setup_form_name">
            <DynamicForm
                :title="$store.getters.t('machine_setup')"
                :form_name="setup_form_name"
                :options_resource="form.parsed_setup"
                v-model="form.setup"
            />
        </v-row>
        <v-row v-for="jfn in job_form_names" :key="jfn.name">
            <DynamicForm
                :title="$store.getters.t(jfn.name)"
                :form_name="jfn.settings"
                :options_resource="form.parsed_setup"
                :value="form.default_job"
                @change="patch_default_job"
            />
        </v-row>
        <!-- <v-select
            outlined
            dense
            hide-hints
            v-model="form.setup.direction"
            :items="direction_types"
            label="Direction"
        ></v-select>
        <v-text-field
            label="Milling tool size"
            v-model="form.setup.milling_tool_size"
            outlined
            dense
            hide-hints
        >
        </v-text-field>
        <v-text-field
            label="Plates nailer tool ID"
            v-model="form.setup.plates_nailer_tool_id"
            outlined
            dense
            hide-hints
        >
        </v-text-field>
        <v-select
            outlined
            dense
            hide-hints
            v-model="form.setup.plates_nailer_tool_mode"
            :items="plates_nailer_tool_mode_types"
            label="Plates nailer tool mode"
        ></v-select> -->
    </v-form>
</template>

<script>
import { backend_api } from "@/utils/api";
import DynamicForm from "@/components/DynamicForm";
import MachineFileUploader from "@/components/company/MachineFileUploader";
export default {
    props: ["value"],
    components: { DynamicForm, MachineFileUploader },
    data: () => ({
        form: {
            model: "mobi_one_v2",
            name: "Default Machine",
            config_file: {},
            setup: {},
            parsed_setup: {},
            default_job: {},
        },
        machine_models: [], // Async loaded
    }),
    computed: {
        model_types() {
            return this.machine_models.map((mm) => ({
                value: mm.name,
                text: mm.description,
            }));
        },
        selected_machine() {
            return this.machine_models.find((mm) => mm.name == this.form.model);
        },
        machine_description_long() {
            if (!this.form.model) return false;
            return this.selected_machine?.description_long;
        },
        setup_form_name() {
            return this.selected_machine?.machine_setup;
        },

        job_form_names() {
            return this.selected_machine?.formats;
        },
        can_upload_config() {
            return ["mobi_one_v2", "mobi_one_v1", "robobrick"].includes(
                this.form.model
            );
        },
    },
    watch: {
        value: {
            handler: function (v) {
                console.log("watcher value");
                this.form = v;
            },
            immediate: true,
        },
        form: {
            handler: function (f) {
                this.$emit("input", f);
            },
            deep: true,
        },
    },
    methods: {
        patch_default_job(event) {
            this.form.default_job = { ...this.form.default_job, ...event };
        },
        load_machine_models() {
            backend_api
                .get("/machine_models/")
                .then((r) => (this.machine_models = r.data));
        },
        config_file_update(event) {
            this.$set(this.form, "config_file", event);
        },
        set_parsed_setup(payload) {
            this.$set(this.form, "parsed_setup", payload);
        },
        validate() {
            // This is used also by parent component
            return this.$refs.form.validate();
        },
        save() {
            if (this.validate()) {
                console.log("[i] Validated. Saving.");
                this.$emit("save");
            } else {
                console.log("[!] Not valid.");
            }
        },
    },
    mounted() {
        this.load_machine_models();
    },
};
</script>
