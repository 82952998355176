import { backend_api } from '@/utils/api'
import bus from '@/utils/event_bus'
import vue from '@/main'
import router from '@/router'


function determineUserLanguage(auth) {
    if (auth.multilang && auth.language) {
        return auth.language
    } else
        return auth.company.language
}

function allowedMobLite(auth) {
    return auth?.company?.m_lite
}

export default {
    namespaced: true,
    state: {
        access_token: localStorage.getItem('access_token'),
        refresh_token: localStorage.getItem('refresh_token'),
        authenticated: !!localStorage.getItem('access_token'),
        user_object: {
            _flag: "not-initialized",
            username: "",
            authorization: {}
        }
    },
    mutations: {
        setTokens(state, data) {
            state.access_token = data.access
            state.refresh_token = data.refresh
            state.authenticated = true
            localStorage.setItem('access_token', state.access_token)
            localStorage.setItem('refresh_token', state.refresh_token)
        },
        refreshToken(state, at) {
            state.access_token = at
            localStorage.setItem('access_token', state.access_token)
        },
        cleanSession(state) {
            state.access_token = ""
            state.refresh_token = ""
            state.authenticated = false
            localStorage.setItem('access_token', "")
            localStorage.setItem('refresh_token', "")
        },
        setUserObject(state, data) {
            state.user_object = data
        }
    },
    actions: {
        login(context, data) {
            return backend_api.post('/token/', data)
                .then(({ data }) => {
                    context.commit('setTokens', data)
                    context.dispatch('refreshBaseData', true, { root: true })
                })
        },
        refresh(context) {
            console.log("[i] Refreshing access token")
            return backend_api.post('/token/refresh/', { refresh: context.state.refresh_token })
                .then(({ data }) => {
                    context.commit('refreshToken', data.access)
                    context.dispatch('refreshBaseData', true, { root: true })
                })
                .catch(() => {
                    bus.$emit("notification", {
                        timeout: 2000,
                        text: "Failed to refresh session. You have to login.",
                        color: "error"
                    });
                })
        },
        getUserObject(context) {
            return backend_api.get('/my_user/')
                .then(({ data }) => {
                    context.commit('setUserObject', data)
                    const lang = determineUserLanguage(data.authorization)
                    vue.$vuetify.lang.current = lang
                    context.dispatch("translations/loadLang", lang, { root: true })
                    if (!allowedMobLite(data.authorization)) {
                        context.commit("cleanSession")
                        router.push("/login")
                        bus.$emit("notification", {
                            timeout: 2000,
                            text: "Your company has no license for mobgenerator.com",
                            color: "error"
                        });
                    }
                })
                .catch(e => console.log(`Cant get User Object: ${e}`))
        },
        postUserSelectedStation(context, uuid) {
            return backend_api.post('/my_user/select_station/', { selected_station_uuid: uuid })
                .then(({ data }) => {
                    context.commit('setUserObject', data)
                })
                .catch(e => console.log(`Cant get User Object: ${e}`))
        }
    },
    getters: {
        viewer_show_qr: state => {
            try {
                return state.user_object.authorization.company.viewer_show_qr
            } catch {
                return true
            }
        },
        viewer_show_rfid: state => {
            try {
                return state.user_object.authorization.company.viewer_show_rfid
            } catch {
                return true
            }
        },
        viewer_hide_hidden_materials: state => {
            try {
                return state.user_object.authorization.company?.viewer_hide_hidden_materials
            } catch {
                return true
            }
        },
        my_station: state => state.user_object.authorization?.production_station_name,
        my_site_object: state => state.user_object.authorization?.production_site_obj,
        my_selected_station: state => state.user_object.authorization?.selected_production_station_name,
        my_selected_site_object: state => state.user_object.authorization?.selected_production_site_obj,
        my_site_uuid: state => state.user_object.authorization?.production_site,
        iam_prod_worker: state => state.user_object.authorization?.production_user,
        my_company: state => state.user_object.authorization?.company,
        androidLicensed: state => state.user_object.authorization?.android_licensed,
    }
}