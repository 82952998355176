<template>
    <v-app>
        <v-app-bar v-if="show_bar" app>
            <div class="d-flex align-center">MOB GENERATOR</div>

            <v-tabs centered class="ml-n9" color="accent">
                <v-tab v-for="link in links" :key="link.route" :to="link.route">
                    {{ link.text }}
                </v-tab>
            </v-tabs>

            <MenuMessages />
            <ButtonLanguage v-if="user.authorization.multilang" />
            <v-btn @click="logout" text color="accent">
                <span class="mr-2">Logout</span>
                <v-icon> mdi-logout </v-icon>
            </v-btn>
        </v-app-bar>

        <v-main>
            <router-view />
            <Notifications />
            <v-overlay :value="loading_overlay">
                <v-progress-circular
                    indeterminate
                    size="64"
                ></v-progress-circular>
            </v-overlay>
        </v-main>
    </v-app>
</template>

<script>
import Notifications from "@/components/Notifications";
import ButtonLanguage from "@/components/account/ButtonLanguage";
import MenuMessages from "@/components/litespec/MenuMessages";
export default {
    components: {
        Notifications,
        ButtonLanguage,
        MenuMessages,
    },
    name: "App",
    data: () => ({}),
    computed: {
        links() {
            let links = [
                { text: "Generate Files", route: "/" },
                { text: "Settings", route: "/setup" },
            ];
            if (this.d2f_permission)
                links.push({ text: "D2F Licenses", route: "/d2f" });
            return links;
        },
        show_bar() {
            return !["login"].includes(this.$route.name);
        },
        loading_overlay() {
            return this.$store.state.lock;
        },
        user() {
            return this.$store.state.session.user_object;
        },
        d2f_permission() {
            return this.$store.state.company.company.max_d2f_licenses > 0;
        },
    },
    methods: {
        logout() {
            this.$store.commit("session/cleanSession");
            this.$router
                .push("/login")
                .catch(() =>
                    console.log(
                        "[!] Can't go to login. Maybe already in login page."
                    )
                );
        },
    },
    mounted() {
        // This is forced because I want the login() method load all data.
        this.logout();
    },
};
</script>
