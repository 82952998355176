<template>
    <v-form ref="form" @keyup.enter.native="save">
        <h2 class="ml-1 mb-3 mt-5 font-weight-light grey--text">
            {{ $store.getters.t("site_parameters") }}
        </h2>
        <div class="d-flex">
            <v-text-field :rules="[(v) => !!v || 'Required']" class="mx-1" outlined label="Name" v-model="form.name">
            </v-text-field>
        </div>
        <div class="d-flex my-3">
            <h2 class="mb-3 font-weight-light grey--text">
                {{ $store.getters.t("stations") }}
            </h2>
            <v-spacer> </v-spacer>
            <v-btn text @click="add_station">
                <v-icon left> mdi-plus </v-icon>
                {{ $store.getters.t("add_station") }}
            </v-btn>
        </div>
        <template v-for="(station, i) in form.stations">
            <v-sheet rounded outlined class="my-3 pa-3" :key="i + '_sheet'">
                <div class="d-flex mb-3" :key="i">
                    <div style="width: 250px">
                        <h4 class="mb-2 font-weight-light grey--text">
                            <span v-if="station.is_last">[{{ $store.getters.t("last") }}]</span>
                            {{ $store.getters.t("station_parameters") }}
                        </h4>
                        <v-text-field :rules="[(v) => !!v || 'Required']" class="mx-1" dense outlined label="Name"
                            v-model="station.name">
                        </v-text-field>
                        <SelectStationKeywords v-model="station.keywords" />
                        <InputZones v-model="station.zones" />
                    </div>
                    <div class="ml-5" style="width: 600px">
                        <div class="d-flex">
                            <h4 class="mb-2 font-weight-light grey--text">
                                {{ $store.getters.t("tasks") }}
                            </h4>
                            <v-spacer> </v-spacer>
                            <v-btn small text @click="add_chi(i)">
                                <v-icon left> mdi-plus </v-icon>
                                {{ $store.getters.t("add_item") }}
                            </v-btn>
                        </div>
                        <template v-for="(chi, j) in station.checklist_items">
                            <div :key="j + '_iconrow'" class="d-flex mx-2">
                                <v-icon small>
                                    mdi-numeric-{{ j + 1 }}-box
                                </v-icon>
                                <v-spacer></v-spacer>
                                <v-btn small class="ml-3 mt-1" icon color="accent" @click="delete_chi(i, j)">
                                    <v-icon small> mdi-delete </v-icon>
                                </v-btn>
                            </div>
                            <v-sheet outlined rounded class="d-flex mx-2 pt-3 px-1" :key="j">
                                <v-text-field class="mx-1" dense outlined :label="$store.getters.t('task_text')"
                                    v-model="chi.text">
                                </v-text-field>
                                <v-checkbox class="mx-3 mb-3 mt-1" outlined :label="$store.getters.t('require_picture')"
                                    v-model="chi.picture">
                                </v-checkbox>
                                <UploadFilePackageField class="my-2" v-model="chi.docs_package" small label="docs"
                                    v-if="station.uuid" :package_params="{
                                        production_line: form.uuid,
                                    }" />
                                <span v-else class="ma-3 grey--text">Save to upload docs.</span>
                            </v-sheet>
                        </template>
                    </div>
                    <v-spacer></v-spacer>
                    <div style="width: 250px">
                        <v-row>
                            <v-spacer></v-spacer>
                            <v-btn color="accent" class="ma-2" text @click="delete_station(i)">
                                <v-icon left> mdi-delete </v-icon>
                                {{ $store.getters.t("delete_station") }}
                            </v-btn>
                        </v-row>
                        <SelectMachineSetup v-model="station.machine_setup" />
                    </div>
                </div>
            </v-sheet>
        </template>
    </v-form>
</template>


<script>
import SelectStationKeywords from "./SelectStationKeywords.vue";
import SelectMachineSetup from "./SelectMachineSetup.vue";
import InputZones from "./InputZones.vue";
import UploadFilePackageField from "../fields/uploads/UploadFilePackageField.vue";
export default {
    components: {
        SelectStationKeywords,
        SelectMachineSetup,
        InputZones,
        UploadFilePackageField,
    },
    props: ["value"],
    data: () => ({
        form: {},
    }),
    watch: {
        value: {
            handler: function (v) {
                console.log("watcher value");
                this.form = v;
            },
            immediate: true,
        },
        form: {
            handler: function (f) {
                this.$emit("input", f);
            },
            deep: true,
        },
    },
    methods: {
        add_chi(i) {
            let sta = this.form.stations[i];
            sta.checklist_items.push({
                text: "",
                picture: false,
            });
        },
        delete_chi(i, j) {
            let sta = this.form.stations[i];
            sta.checklist_items.splice(j, 1);
        },
        add_station() {
            this.form.stations.push({
                name: "",
                default_zone: 100,
                zones: [],
                checklist_items: [],
                keywords: [],
                order: 0,
                is_last: false,
            });
            this.$emit("touched_stations");
        },
        delete_station(i) {
            this.form.stations.splice(i, 1);
            this.$emit("touched_stations");
        },
        validate() {
            return this.$refs.form.validate();
        },
    },
};
</script>